var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "indigo" } },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("mdi-arrow-left")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.pageLabel
        ? _c("span", [_vm._v("Back to " + _vm._s(_vm.pageLabel))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }